import { parse } from "path";



export function displayComponent(array: string[]) {
    let a = false;
    if (array.length === 0) {
        return a;
    }
    array.forEach((element) => {
        if (element === "" || typeof(element) === "undefined" || element == null) {
            a = false;
            return;
        } else {
            a = true;
            return;
        }
    });
    return a;
}

export function applicationOpen(subProgramme: ISubProgramme): boolean {
    let closeDateObject = new Date();
    let openDateObject = new Date();
    if (subProgramme != null) {
        if (subProgramme.ApplicationOpeningDate != null) {
            if (subProgramme.ApplicationOpeningTime && subProgramme.ApplicationOpeningTime != null) {
                let strDateTime = subProgramme.ApplicationOpeningDate.replaceAll("/", "-") + "T" + subProgramme.ApplicationOpeningTime + "+02:00"; //get to str format yyyy-mm-dThh:mm:ss GMT+2
                openDateObject = new Date(strDateTime);
            } else {
                openDateObject = new Date(subProgramme.ApplicationOpeningDate);
            }
        }
        if (subProgramme.ApplicationClosingDate != null) {
            if (subProgramme.ApplicationClosingTime && subProgramme.ApplicationClosingTime != null) {
                let strDateTime = subProgramme.ApplicationClosingDate.replaceAll("/", "-") + "T" + subProgramme.ApplicationClosingTime + "+02:00"; //get to str format yyyy-mm-dThh:mm:ss GMT+2
                closeDateObject = new Date(strDateTime);
            } else {
                closeDateObject = new Date(new Date(subProgramme.ApplicationClosingDate).setHours(23, 59, 59, 59)); // added time so that closing date is the end of the closing day.
            }
        }
    } 
    const currentDate = new Date();
    if (closeDateObject >= currentDate && openDateObject <= currentDate) {
        return true;
    } else {
        return false;
    }
}

export function getUniqueKey(currentValue: Number = 0) : Number {
    let newValue = (new Date()).getTime();
    //console.log(`Current Value: ${currentValue} New Value: ${newValue} : ${(newValue - currentValue)}`)
    if (currentValue > 0 && ((newValue - currentValue) < 1000)){
        return currentValue;
    }
    else{
        return newValue;
    }
}

export const isDebugMode = computed(() => {
    return () => {
        // NOTE : NDW - 2023-01-15 - This is a hack to get the debug mode to work, (Uncaught ReferenceError ReferenceError: window is not defined)
        try
        {
            return window.location.search.includes('debug=true');
        }
        catch
        {
            return false;
        }
    };
});

export const isPreviewMode = computed(() => {
    return () => {
        const route = useRoute();
        let preview = route.query.preview;
        if (preview)
        {
            return JSON.parse(preview)
        }
        else
        {
            return false;
        } 
    };
});

export const isServerInfoMode = computed(() => {
    return () => {
        const route = useRoute();
        let serverInfo = route.query.serverInfo;
        if (serverInfo)
        {
            return JSON.parse(serverInfo)
        }
        else
        {
            return false;
        } 
    };
});

function doCheckIsShown(source: string, compare: string, receiver: string)
{
    try{
        if (source?.toString() === compare?.toString() && receiver === 'showOtherField')
        {
            return (source?.toString() != '');//true;
        }
        else if (source?.toString() !== compare?.toString() && receiver === 'showAltOtherField')
        {
            return (source?.toString() != '');// true;
        }
        else
        {
            return false;
        }
    }
    catch(ex){
        console.log(ex);
        return false;
    }
}

function checkIsShown(source: string, compare: string, formField:  z.infer<typeof FieldInfoItem>){
    let _isShown = doCheckIsShown(source, compare, formField.receiver);
    if (_isShown === false && source != '')
    {
        let isJsonString = false;
        if (typeof compare === 'string'){
            try{
                let parseString = JSON.parse(compare);
                if (parseString != null  && typeof parseString === 'object'){
                    isJsonString = true;
                }
            }
            catch{}
        }
        else if (typeof compare === 'object') {
            isJsonString = true;
        }
        else {
            //debugger;
        }
        if (isJsonString === true)
        {
            let attCollection = compare as z.infer<typeof AttributeMappings>;
            if (typeof compare === 'string' && attCollection == null){
                attCollection = JSON.parse(compare) as z.infer<typeof AttributeMappings>;
            }
            try{
                let checkData = attCollection.find(_ => _.name == "alternative-receiver");
                let checkDataValue = attCollection.find(_ => _.name == "data-other");
                if (checkData != null && checkDataValue != null){
                    _isShown = doCheckIsShown(source, checkDataValue.value, checkData.value);
                }
            }
            catch(ex){
                console.log(ex);
            }
        }
    }
    return _isShown;
}

function doCheck(senderField:  z.infer<typeof FieldInfoItem>, formField:  z.infer<typeof FieldInfoItem>, dataOther : z.infer<typeof AttributeMappings>){
    if ((senderField.value.Value != undefined 
        || senderField.value.Value != null) && senderField.value.Value != Guid.Empty)
    {
        formField.isShown = checkIsShown(senderField.value.Value, dataOther.value, formField);
    }
    else if ((senderField.value != undefined 
        || senderField.value != null) && senderField.value != Guid.Empty)
    {
        formField.isShown = checkIsShown(senderField.value, dataOther.value, formField);
    }
    else if (senderField.value === Guid.Empty)
    {
        formField.isShown = false;
    }
    // else{
    //     debugger;
    // }
}

function isJson(input: string) : boolean 
{
    if (input.startsWith('{') && input.endsWith('}') || input.startsWith('[') && input.endsWith(']'))
    {
        return true;
    }
    else
    {
        return false;
    }
}

export function fieldVisibility(formField:  z.infer<typeof FieldInfoItem>, allFields : z.infer<typeof FieldInfoItem>[], forRefresh:boolean = false) : boolean {
    let hasRefreshed = false;
    try{
        if (formField != null || formField != undefined)
        {
            let currentIsShown = formField.isShown;
            /// NOTE : NDW - 2023-01-15 - Should the field be manditory if shown?
            if (typeof formField.attributes === 'string' && (formField.attributes != undefined || formField.attributes != null) && formField.attributes.length > 0){ 
                let attCollection = isJson(formField.attributes) ? JSON.parse(formField.attributes) as z.infer<typeof AttributeMappings> : [];
                let dataOtherFilter = attCollection.filter(_  => _.name === "data-other" || _.name === "alternative-data-other");
                let doneCheck = false;
                dataOtherFilter.forEach(dataOther => {
                    var senderId = formField.sender;
                    if (doneCheck === true) return;
    
                    let attCollection = isJson(formField.attributes) ?  JSON.parse(formField.attributes) as z.infer<typeof AttributeMappings> : [];
                    let checkData = attCollection.filter(_ => _.name == "alternative-data-other") as z.infer<typeof AttributeMappings>;;
                    if (checkData != null && checkData.length > 0)
                    {
                        checkData.forEach(_ => {
                            if (doneCheck === true) return;
                            senderId = _.value.find(_ => _.name == "alternative-sender")?.value;
                            let senderField = allFields.find(_ => isSenderField(_.id, senderId, _));
                            if (senderField != null)
                            {
                                doCheck(senderField, formField, dataOther);
                                if (formField.isShown === true)
                                {
                                    doneCheck = true;
                                    return;
                                } 
                            }
                        });
                    }
                    else
                    {
                        let senderField = allFields.find(_ => isSenderField(_.id, senderId, _));
                        if (senderField != null)
                        {
                            doCheck(senderField, formField, dataOther);
                        }
                    }
                });
    
                // if (formField.isShown === false && formField.value != ''){
                //     formField.value = '';
                // }
    
                if (currentIsShown != formField.isShown)
                {
                    let olduniqueKey = formField.uniqueKey;
                    formField.uniqueKey = getUniqueKey(formField.uniqueKey);
                    console.log("Field " + formField.id + " is now " + formField.isShown +" and has a new unique key of " + formField.uniqueKey + " from " + olduniqueKey);
                    hasRefreshed = true;
                }
                else if (forRefresh === true){
                    formField.uniqueKey = getUniqueKey(formField.uniqueKey);
                    hasRefreshed = true;
                }
            }
        }
    }
    catch(ex){
        debugger;
        console.log(ex); 
    }

    return hasRefreshed;
}

export function isSenderField(source : string,  checkFieldId : string, formField: z.infer<typeof FieldInfoItem>) : boolean{
    if (source === checkFieldId)
    {
        return true;
    }
    else if (formField.attributes != null && formField.attributes.length > 0)
    {
        let isJsonString = false;
        /// HACK TO FIX THE ISSUE WITH THE JSON NOT BEING PARSED CORRECTLY
        try{
            JSON.parse(formField.attributes);
            isJsonString = true;
        }
        catch{
            isJsonString = false;
        }
        if (isJsonString === false){
            return false;
        }
        let attCollection = JSON.parse(formField.attributes) as z.infer<typeof AttributeMappings>;
        let checkData = attCollection.filter((_: any) => _.name == "alternative-data-other");
        if (checkData.length > 0)
        {    
            let check = false;
            checkData.forEach((element: any) => {
                let _c = element.value.find((a: any) => a.name == "alternative-sender");
                if (_c.value === checkFieldId)
                {  
                    check = true;
                    return;
                }
            });
            return check;
        }
        else
        {
            return false;
        }
    }
    else
    {
        return false;
    }
} 

export function addAlternativeAttribute(formField: z.infer<typeof FieldInfoItem>, dataOther: string, alternativeSender : string, alternativeReceiver : string)
{
    if (formField.attributes == null){
        formField.attributes = "[]"
    }
    
    let attCollection = JSON.parse(formField.attributes) as z.infer<typeof AttributeMappings>;
    if (attCollection != null)
    {
        let alternativeDataOther = 
        {
            name: "alternative-data-other", 
            value: 
            [
                {name: "data-other", value: dataOther},
                {name: "alternative-sender", value: alternativeSender},
                {name: "alternative-receiver", value: alternativeReceiver}
            ]
        }
        
        attCollection.push(alternativeDataOther);

        formField.attributes = JSON.stringify(attCollection);
        formField.isShown = false;
    }
}